import * as React from "react"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import {
  Container,
  Flex,
  Box,
  Space,
  Heading,
  Text,
  Avatar,
} from "../components/ui"
import { avatar as avatarStyle } from "../components/ui.css"
import * as styles from "./blog-post.css"
import Search from "../components/search"
import AddBlock from "../components/Ad/AdBlock"



const searchIndices = [{ name: `blogs`, title: `blogs` }]

export default function BlogPost({pageContext}) {

  return (
    <Layout {...pageContext} description={pageContext.summary}>
      <Container> 
      <Search indices={searchIndices} />
        <Box paddingY={5}>
          <Heading as="h1" center>
            {pageContext.title}
          </Heading>
          <Space size={4} />
        
          <Space size={4} />
          <Text center>{`${new Date(pageContext.date).toDateString()}` }</Text>
          <Space size={4} />
          {pageContext.image && (
            <GatsbyImage
              alt={pageContext.image.alt}
              image={pageContext.image.gatsbyImageData}
            />
          )}
          <Space size={5} />
          {pageContext.text1 && (
           <ReactMarkdown children={pageContext.text1.text1} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image1 && (
            <GatsbyImage
              alt={pageContext.image1.alt}
              image={pageContext.image1.gatsbyImageData}
            />
          )}
           {pageContext.text2 && (
           <ReactMarkdown children={pageContext.text2.text2} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image2 && (
            <GatsbyImage
              alt={pageContext.image2.alt}
              image={pageContext.image2.gatsbyImageData}
            />
          )}
           <AddBlock/>
           {pageContext.text3 && (
           <ReactMarkdown children={pageContext.text3.text3} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image3 && (
            <GatsbyImage
              alt={pageContext.image3.alt}
              image={pageContext.image3.gatsbyImageData}
            />
          )}
           {pageContext.text4 && (
           <ReactMarkdown children={pageContext.text4.text4} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image4 && (
            <GatsbyImage
              alt={pageContext.image4.alt}
              image={pageContext.image4.gatsbyImageData}
            />
          )}
           {pageContext.text5 && (
           <ReactMarkdown children={pageContext.text5.text5} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image5 && (
            <GatsbyImage
              alt={pageContext.image5.alt}
              image={pageContext.image5.gatsbyImageData}
            />
          )}
           {pageContext.text6 && (
           <ReactMarkdown children={pageContext.text6.text6} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image6 && (
            <GatsbyImage
              alt={pageContext.image6.alt}
              image={pageContext.image6.gatsbyImageData}
            />
          )}
           {pageContext.text7 && (
           <ReactMarkdown children={pageContext.text7.text7} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image7 && (
            <GatsbyImage
              alt={pageContext.image7.alt}
              image={pageContext.image7.gatsbyImageData}
            />
          )}
           {pageContext.text8 && (
           <ReactMarkdown children={pageContext.text8.text8} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image8 && (
            <GatsbyImage
              alt={pageContext.image8.alt}
              image={pageContext.image8.gatsbyImageData}
            />
          )}
           {pageContext.text9 && (
           <ReactMarkdown children={pageContext.text9.text9} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image9 && (
            <GatsbyImage
              alt={pageContext.image9.alt}
              image={pageContext.image9.gatsbyImageData}
            />
          )}
           {pageContext.text10 && (
           <ReactMarkdown children={pageContext.text10.text10} remarkPlugins={[remarkGfm]} />
           )}
           {pageContext.image10 && (
            <GatsbyImage
              alt={pageContext.image10.alt}
              image={pageContext.image10.gatsbyImageData}
            />
          )}
        </Box>
      </Container>
    </Layout>
  )
}
