import React from 'react'


const AdBlock = () => (
    <ins className="adsbygoogle"
     style={{"display":"block", "textAlign":"center"}}
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-5723471843217494"
     data-ad-slot="8402101870"></ins>

)

export default AdBlock  